<template>
  <div style="display: contents">
    <loading :active="isLoading" :can-cancel="false" :is-full-page="false" />
    <CompetitionUpsert v-if="!isLoading" :initVal="details" :isEditRoute="true" />
  </div>
</template>
<script>
import CompetitionUpsert from '@/components/Competitions/CompetitionUpsert.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    CompetitionUpsert,
  },
  data() {
    return {
      isLoading: true,
      details: null,
    }
  },
  computed: {
    ...mapGetters({ competitionDetails: 'request/competitionDetails', catalogList: 'request/catalogList' }),
  },
  created() {
    this.$store
      .dispatch('request/getCompetitionDetails', { id: this.$route.query.id })
      .then(() => {
        this.details = { ...this.$route.params, ...this.competitionDetails }
        if (!this.catalogList) {
          this.$store
            .dispatch('request/getCatalogList')
            .then(() => (this.isLoading = false))
            .catch(() => (this.isLoading = false))
        } else {
          this.isLoading = false
        }
      })
      .catch(() => (this.isLoading = false))
  },
}
</script>
