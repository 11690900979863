<template>
  <div class="row justify-content-center">
    <loading :active="isLoading" :can-cancel="false" is-full-page />
    <div class="col-lg-5 col-md-7 card bg-secondary shadow border-0">
      <form id="login-form" @submit.prevent="handleSubmit" class="card-body login-card px-lg-4 py-lg-5">
        <b-form-group :label="$t('auth.email')" label-for="login-input-1" class="m-0">
          <b-form-input id="login-input-1" v-model="email" type="email" :placeholder="$t('input.enter_email')" required class="text-md" autocomplete="email" />
        </b-form-group>
        <b-form-group :label="$t('auth.password')" label-for="login-input-2" class="mt-3 mb-0">
          <b-input-group>
            <b-form-input
              id="login-input-2"
              type="password"
              v-model="password"
              autocomplete="current-password"
              :placeholder="$t('auth.password')"
              required
              v-on:keyup.enter="handleSubmit"
              class="text-md">
            </b-form-input>
            <b-input-group-append>
              <b-button @click="textToggle" variant="outline-light"><b-icon :icon="eyeClass" /></b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>

        <div class="mt-1 w-100 d-flex flex-row flex-wrap justify-content-between">
          <b-form-checkbox v-model="remember" value="remember" unchecked-value="not_remember" class="login-checkbox d-inline-block text-sm mt-3">
            {{ $t('auth.remember_me') }}
          </b-form-checkbox>
          <span class="action text-sm mt-3" v-b-modal.forget-pass>{{ $t('auth.forget_password') }}?</span>
        </div>
        <div class="mt-3 d-flex flex-row flex-nowrap justify-content-between align-items-center">
          <div class="w-50">
            <b-button
              v-for="(opt, index) in langOpts"
              :key="'lang-opt-' + index"
              variant="outline-primary"
              :class="`border-0 avatar avatar-sm rounded avatar-image bg-white${index > 0 ? ' ml-5' : ''}`"
              :style="`background-image: url(${opt.img}); width: 26px; height: 26px; opacity: ${$i18n.locale === opt.value ? 1 : 0.65}`"
              @click="setLang(opt.value)"
              :disabled="$i18n.locale === opt.value"
              ><span :class="`text-uppercase ml-5 ${$i18n.locale === opt.value ? 'text-primary font-weight-bold' : 'text-dark'}`">{{ opt.value }}</span>
            </b-button>
          </div>
          <b-button variant="basic" type="submit" form="login-form" class="" @keyup.enter="handleSubmit">{{ $t('auth.sign_in') }}</b-button>
        </div>
        <b-modal
          lazy
          centered
          id="forget-pass"
          :title="$t('auth.reset_password')"
          title-class="w-100 d-flex justify-content-center align-self-center"
          header-class="py-2"
          footer-class="py-2"
          body-class="py-0"
          ><p v-if="!email" class="m-0">⚠ {{ $t('auth.no_email_provided') }}</p>
          <form v-else id="login-forget-form" @submit.prevent="forgetPass" inline>
            <span> ⚠ {{ $t('auth.reset_password_notice[0]') }}</span>
            <b-form-input :value="email" type="email" required class="d-inline-block mr-1" style="width: 10rem" />
            <span>{{ $t('auth.reset_password_notice[1]') }}</span>
          </form>
          <template #modal-footer="{ hide }">
            <b-button variant="outline-secondary" @click="hide">{{ $t('action.cancel') }}</b-button>
            <b-button v-if="email" variant="outline-primary" type="submit" form="login-forget-form">{{ $t('action.confirm') }}</b-button>
          </template>
        </b-modal>
      </form>
    </div>
  </div>
</template>
<script>
import langMixin from '@/mixins/lang-mixin'
import { notifyError } from '@/utils/index'
import { mapGetters } from 'vuex'
export default {
  name: 'login',
  mixins: [
    langMixin, // langOpts, setLang
  ],
  data: () => ({
    isLoading: false,
    eyeClass: 'eye-slash',
    email: '',
    password: '',
    remember: 'not_remember',
  }),
  computed: {
    ...mapGetters({ selfStore: 'store' }),
    isAuthenticated() {
      return this.$store.getters.isAuthenticated()
    },
  },
  methods: {
    handleSubmit() {
      if (!this.isLoading) {
        this.isLoading = true
        this.$store
          .dispatch('login', { name: this.email, password: this.password })
          .then(() => {
            this.isLoading = false
            if (this.remember === 'remember') {
              localStorage.setItem('Rkings_login_remember_me', this.remember)
              localStorage.setItem('Rkings_login_email', this.email)
            } else if (this.remember != 'remember') {
              localStorage.setItem('Rkings_login_remember_me', 'not_remember')
              localStorage.setItem('Rkings_login_email', '')
            }
            if (this.selfStore == 1) {
              this.setLang('en')
            }
          })
          .catch((error) => {
            this.isLoading = false
            notifyError(error, this.$t('auth.login_notice[1]'))
          })
      }
    },
    textToggle() {
      var input = document.getElementById('login-input-2')
      if (input.type === 'password') {
        input.type = 'text'
        this.eyeClass = 'eye'
      } else {
        input.type = 'password'
        this.eyeClass = 'eye-slash'
      }
    },
    forgetPass() {
      this.isLoading = true
      this.$store
        .dispatch('request/forgetPass', {
          email: this.email,
          successMsg: this.$t('auth.reset_password_notice[2]'),
          errorMsg: this.$t('auth.reset_password_notice[3]'),
        })
        .then((res) => {
          this.isLoading = false
          if (res.status === 200) {
            this.$bvModal.hide('forget-pass')
          }
        })
        .catch(() => (this.isLoading = false))
    },
  },
  created() {
    if (localStorage.getItem('Rkings_login_remember_me') === 'remember') {
      this.remember = 'remember'
      this.email = localStorage.getItem('Rkings_login_email')
    }
  },
}
</script>
<style scoped>
::v-deep .modal-dialog {
  width: fit-content;
  min-width: 30%;
  max-width: 50%;
}
@media screen and (max-width: 1024px) {
  ::v-deep .modal-dialog {
    min-width: 60%;
    max-width: 80%;
  }
}
</style>
<style>
.login-checkbox {
  padding-left: 2rem;
}
.login-checkbox label::before,
.login-checkbox label::after {
  left: -2rem;
}
</style>
