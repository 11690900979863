<template>
  <div style="display: contents">
    <loading :active="isLoading" :can-cancel="false" :is-full-page="false" />
    <template v-if="!isLoading">
      <ProductUpsert v-if="details" :initVal="details" />
      <ProductUpsert v-else />
    </template>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import ProductUpsert from '@/components/Product/ProductUpsert.vue'

export default {
  components: {
    ProductUpsert,
  },
  data() {
    return {
      isLoading: true,
      details: null,
    }
  },
  computed: {
    ...mapGetters({ productDetails: 'request/productDetails' }),
  },
  mounted() {
    if (this.$route.params.id) {
      this.$store
        .dispatch('request/getProductDetails', { id: this.$route.params.id })
        .then(() => {
          const data = { ...this.$route.params, ...this.productDetails }
          this.details = {}
          // filter out some unnecessary data when duplicating product
          for (const key in data) {
            if (['store_id', 'cost', 'currency', 'description', 'name', 'stock_amount', 'topImage', 'type'].indexOf(`${key}`) > -1) {
              this.details[key] = data[key]
            }
          }
          this.details['status'] = 1
          this.details['attributes'] = this.productDetails.attributes.filter((attr) => attr.code !== 'invoice')
          this.isLoading = false
        })
        .catch(() => (this.isLoading = false))
    } else {
      this.isLoading = false
    }
  },
}
</script>
