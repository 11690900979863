<template>
  <card body-classes="p-0 d-flex flex-column" class="table-custom">
    <div class="px-4 py-3 w-100 d-flex flex-row justify-content-end">
      <b-button variant="basic" @click="openUpsert(null)"><b-icon-plus class="mr-1" />{{ $t('donation.create_new_donation') }}</b-button>
    </div>
    <b-table :fields="fields" :items="items" show-empty :empty-text="$t('notify.table_no_records')" small hover responsive head-variant="light" class="m-0 p-0" primary-key="id" sticky-header="100%">
      <template #cell(title)="{ value }">
        <span v-b-tooltip.hover.right="value">{{ value }}</span>
      </template>
      <template #cell(is_active)="{ value }">
        <bool-badge :value="value" :text="value == 1 ? $t('status.active') : $t('status.inactive')" />
      </template>
      <template #cell(image_url)="{ value }">
        <b-img-lazy v-if="value" :src="value" :alt="shortenString(value, 13, true)" fluid block rounded style="max-height: 4rem" v-b-tooltip.hover.right="value" />
      </template>
      <template #cell(description)="data">
        <b-form-textarea v-model="data.value" disabled size="lg" rows="2" no-resize style="font-size: 0.85rem; min-width: 10rem" />
      </template>
      <template #cell(action)="{ item }">
        <b-button @click="openUpsert(item)" v-b-tooltip.hover.top="$t('action.edit')"><b-icon-pencil /></b-button>
      </template>
    </b-table>
    <paginate v-if="items.length > 0" :queries="queryParams" :fetcher="getDonationList" :total="donationList.total" class="card-footer" />
    <DonationUpsert :donation="actionTarget" />
  </card>
</template>
<script>
import DonationUpsert from '@/components/DonationUpsert.vue'
import { formatLocalDateTime } from '@/utils/dateTimeUtils'
import { shortenString } from '@/utils/index'
import { mapGetters } from 'vuex'

export default {
  components: { DonationUpsert },
  data() {
    return {
      isLoading: true,
      queryParams: { page: 1, perPage: 10, ...this.$route.params?.prev },
      actionTarget: null,
    }
  },
  computed: {
    ...mapGetters({ donationList: 'request/donationList' }),
    fields() {
      return [
        { key: 'id', label: this.$t('general.id'), sortable: true },
        { key: 'title', label: this.$t('general.title'), sortable: true, stickyColumn: true, tdClass: 'text-truncate' },
        { key: 'is_active', label: this.$t('general.status'), tdClass: 'text-uppercase' },
        { key: 'image_url', label: this.$t('donation.image_url') },
        { key: 'description', label: this.$t('general.description') },
        { key: 'action', label: this.$tc('general.action', 1), class: 'text-center' },
        { key: 'created_at', sortable: true, formatter: (v) => formatLocalDateTime(v) },
        { key: 'updated_at', sortable: true, formatter: (v) => formatLocalDateTime(v) },
      ]
    },
    items() {
      return this.donationList?.data ?? []
    },
  },
  methods: {
    getDonationList() {
      this.isLoading = true
      this.$store
        .dispatch('request/getDonationList', this.queryParams)
        .then(() => (this.isLoading = false))
        .catch(() => (this.isLoading = false))
    },
    openUpsert(obj) {
      this.actionTarget = obj
      this.$bvModal.show('upsert-donation')
    },
    shortenString,
  },
  created() {
    this.getDonationList()
  },
  beforeRouteLeave(to, from, next) {
    this.$set(from.params, 'preservedParams', this.queryParams)
    return next()
  },
}
</script>
<style scoped>
::v-deep .modal-dialog {
  width: fit-content;
  min-width: 50%;
  max-width: 75%;
}
</style>
