<template>
  <div style="display: contents">
    <loading :active="isLoading" :can-cancel="false" :is-full-page="false" />
    <ProductUpsert v-if="!isLoading" :initVal="details" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import ProductUpsert from '@/components/Product/ProductUpsert.vue'

export default {
  components: {
    ProductUpsert,
  },
  data() {
    return {
      isLoading: true,
      details: null,
    }
  },
  computed: {
    ...mapGetters({ productDetails: 'request/productDetails' }),
  },
  mounted() {
    this.$store
      .dispatch('request/getProductDetails', { id: this.$route.query.id })
      .then(() => {
        this.details = { ...this.$route.params, ...this.productDetails }
        this.isLoading = false
      })
      .catch(() => (this.isLoading = false))
  },
}
</script>
