<template>
  <card body-classes="p-0 d-flex flex-column" class="table-custom">
    <loading :active="isLoading" :can-cancel="false" :is-full-page="false" />
    <div class="px-4 pt-3 pb-2 d-flex flex-row flex-wrap align-items-start">
      <b-form-select value="GBP" disabled class="mr-2" style="width: 80px">
        <b-form-select-option value="GBP">{{ $t('gbp') }}</b-form-select-option>
      </b-form-select>
      <div class="d-flex flex-row flex-nowrap align-items-center justify-content-lg-between flex-grow-1">
        <button-group :onSearch="onSearch" :onReset="onReset" />
        <b-button class="ml-2" variant="basic" @click="onUpsert(null)"><b-icon-plus class="mr-1" />{{ $t('topup.create_topup_config') }}</b-button>
      </div>
    </div>
    <b-table :fields="fields" :items="items" show-empty :empty-text="$t('notify.table_no_records')" small hover responsive class="m-0 p-0" head-variant="light" primary-key="id">
      <template #cell(is_active)="{ value }">
        <bool-badge :value="value" :text="value == 1 ? $t('status.active') : $t('status.inactive')" />
      </template>
      <template #cell(action)="{ item }">
        <b-button @click="onUpsert(item)" v-b-tooltip.hover.top="$t('action.edit')"><b-icon-pencil /></b-button>
      </template>
    </b-table>
    <paginate v-if="items.length > 0" :queries="queries" :fetcher="getTopupList" :total="topupList.total" class="card-footer" />

    <TopupEdit v-if="items.length > 0" :details="actionTarget" :getList="getTopupList" />
  </card>
</template>
<script>
import TopupEdit from '@/components/TopupEdit.vue'
import { formatLocalDateTime } from '@/utils/dateTimeUtils'
import { formatWithCurrency } from '@/utils/numberUtils'
import { mapGetters } from 'vuex'

export default {
  components: { TopupEdit },
  data() {
    return {
      isLoading: false,
      queries: {
        currency: 'GBP',
        page: 1,
        perPage: 10,
        ...this.$route.params?.prev,
      },
      actionTarget: null,
    }
  },
  computed: {
    ...mapGetters({ topupList: 'request/topupList' }),
    fields() {
      return [
        { key: 'id', label: this.$t('general.id') },
        { key: 'currency_code', label: this.$t('general.currency') },
        { key: 'is_active', label: this.$t('general.status') },
        { key: 'payable_amount', label: this.$t('topup.payable'), formatter: (v, k, i) => '≥ ' + formatWithCurrency(v, i.currency_code), class: 'text-right' },
        { key: 'reward_amount', label: this.$t('topup.reward_amount'), formatter: (v, k, i) => (v && v > 0 ? formatWithCurrency(v, i.currency_code) : this.$t('no_data')), class: 'text-right' },
        { key: 'reward_rate', label: this.$t('topup.reward_rate'), formatter: (v) => (v && v > 0 ? v * 100 + '%' : this.$t('no_data')), class: 'text-right' },
        { key: 'start_time', label: this.$t('general.start_time'), sortable: true, formatter: (v) => formatLocalDateTime(v) },
        { key: 'end_time', label: this.$t('general.end_time'), sortable: true, formatter: (v) => formatLocalDateTime(v) },
        { key: 'action', label: this.$tc('general.action', 1), class: 'text-center px-2' },
        { key: 'admin', label: this.$t('general.updated_by'), formatter: (v, k, i) => (!i.admin_user_id ? this.$t('no_data') : v ? v.email : i.admin_user_id) },
      ]
    },
    items() {
      return this.topupList?.data ?? []
    },
  },
  methods: {
    getTopupList(_params) {
      this.isLoading = true
      const params = { ...this.queries, ..._params }
      this.$store
        .dispatch('request/getTopupList', params)
        .then(() => (this.isLoading = false))
        .catch(() => (this.isLoading = false))
    },
    onSearch() {
      this.queries.page = 1
      this.getTopupList({ errorMsg: this.$t('topup.search_failed') })
    },
    onReset() {
      this.onSearch()
    },
    onUpsert(row) {
      this.actionTarget = row
      this.$bvModal.show('topup-edit')
    },
  },
  created() {
    this.getTopupList()
  },
  beforeRouteLeave(to, from, next) {
    this.$set(from.params, 'preservedParams', this.queries)
    return next()
  },
}
</script>
