<template>
  <card body-classes="p-0 d-flex flex-column" class="table-custom">
    <loading :active="isLoading" :can-cancel="false" :is-full-page="false" />
    <div class="px-4 pt-3 pb-2 d-flex flex-row flex-wrap align-items-start">
      <b-form-select v-model="queries.currency" :disabled="!allowMultiStores" class="mb-2 mr-2" style="width: 110px">
        <b-form-select-option :value="null">{{ $t('general.all') + ' ' + $t('general.currency') }}</b-form-select-option>
        <b-form-select-option value="GBP">{{ $t('gbp') }}</b-form-select-option>
        <b-form-select-option value="MXN">{{ $t('mxn') }}</b-form-select-option>
      </b-form-select>
      <b-form-select v-model="queries.type" class="mb-2 mr-2" style="width: 110px">
        <b-form-select-option selected disabled hidden :value="null">{{ $t('general.type') }}</b-form-select-option>
        <b-form-select-option value="weekly">{{ $t('cashback.config_types[0]') }}</b-form-select-option>
        <b-form-select-option value="monthly">{{ $t('cashback.config_types[1]') }}</b-form-select-option>
        <b-form-select-option value="promotion">{{ $t('cashback.config_types[2]') }}</b-form-select-option>
      </b-form-select>
      <div class="d-flex flex-row flex-wrap align-items-center justify-content-lg-between flex-grow-1">
        <button-group :onSearch="onSearch" :onReset="onReset" class="mb-2 mr-2" />
        <b-button class="mb-2" variant="basic" @click="onUpsert(null)"><b-icon-plus class="mr-1" />{{ $t('cashback.add_config') }}</b-button>
      </div>
    </div>

    <b-table :fields="fields" :items="items" show-empty :empty-text="$t('notify.table_no_records')" small hover responsive class="m-0 p-0" head-variant="light" primary-key="id">
      <template #cell(target_amount)="{ value, item }">
        <span v-b-tooltip.hover.right="item.comment ? item.comment : $t('cashback.no_comment')">{{ value }}</span>
      </template>
      <template #cell(is_active)="{ value }">
        <bool-badge :value="value" :text="value == 1 ? $t('status.active') : $t('status.inactive')" />
      </template>
      <template #cell(action)="{ item }">
        <b-button @click="onUpsert(item)" v-b-tooltip.hover.top="$t('action.edit')"><b-icon-pencil /></b-button>
      </template>
    </b-table>
    <paginate v-if="items.length > 0" :queries="queries" :fetcher="getList" :total="options.total" class="card-footer" />
    <CashbackOptionEdit v-if="items.length > 0" :details.sync="actionTarget" :getList="getList" />
  </card>
</template>
<script>
import CashbackOptionEdit from '@/components/Cashback/CashbackOptionEdit.vue'
import { formatWithCurrency } from '@/utils/numberUtils'
import { mapGetters } from 'vuex'

export default {
  components: { CashbackOptionEdit },
  data() {
    const defCurr = this.$store.getters['allowMultiStores'] ? null : ['GBP', 'MXN'][this.selfStore - 1]
    return {
      isLoading: true,
      fields: [
        { key: 'id', label: this.$t('general.id') },
        { key: 'currency_code', label: this.$t('general.currency') },
        { key: 'target_amount', label: this.$t('cashback.band'), formatter: (v, k, i) => '≥ ' + formatWithCurrency(v, i.currency_code), class: 'text-right' },
        { key: 'cashback_rate', label: this.$t('cashback.rate'), formatter: (v) => (v && v > 0 ? v * 100 + '%' : this.$t('no_data')), class: 'text-right' },
        { key: 'cashback_amount', label: this.$tc('general.amount', 1), formatter: (v, k, i) => (v && v > 0 ? formatWithCurrency(v, i.currency_code) : this.$t('no_data')), class: 'text-right' },
        { key: 'type', label: this.$t('general.type'), tdClass: 'text-capitalize' },
        { key: 'is_active', label: this.$t('general.status') },
        { key: 'action', label: this.$tc('general.action', 1), class: 'text-center px-2' },
        { key: 'admin', label: this.$t('general.updated_by'), formatter: (v, k, i) => (v ? v.name ?? v.email : i.admin_user_id ? i.admin_user_id : this.$t('no_data')) },
      ],
      queries: {
        currency: defCurr,
        type: null,
        page: 1,
        perPage: 10,
        ...this.$route.params?.prev,
      },
      actionTarget: null,
    }
  },
  computed: {
    ...mapGetters({ options: 'request/cashbackOptions', allowMultiStores: 'allowMultiStores', selfStore: 'store' }),
    items() {
      return this.options?.data ?? []
    },
  },
  methods: {
    getList(_params) {
      this.isLoading = true
      const params = { ...this.queries, ..._params }
      this.$store
        .dispatch('request/getCashbackOptions', params)
        .then(() => (this.isLoading = false))
        .catch(() => (this.isLoading = false))
    },
    onReset() {
      this.queries.currency = this.allowMultiStores ? null : ['GBP', 'MXN'][this.selfStore - 1]
      this.queries.type = null
      this.onSearch()
    },
    onSearch() {
      this.queries.page = 1
      this.getList({ errorMsg: this.$t('cashback.search_failed') })
    },
    onUpsert(row) {
      this.actionTarget = row
      this.$bvModal.show('cashback-config-upsert')
    },
  },
  mounted() {
    this.queries.currency = this.allowMultiStores ? null : ['GBP', 'MXN'][this.selfStore - 1]
    this.getList()
  },
  beforeRouteLeave(to, from, next) {
    this.$set(from.params, 'preservedParams', this.queries)
    return next()
  },
}
</script>
