<template>
  <b-modal
    lazy
    centered
    :id="id ? id : 'cashback-config-upsert'"
    :title="details ? $t('cashback.edit_config') : $t('cashback.add_config')"
    title-class="w-100 d-flex justify-content-center align-self-center"
    header-class="w-100 py-2"
    body-class="py-0"
    footer-class="py-2"
    @hidden="onHide">
    <b-form @submit.prevent="onSubmit" id="cashback-edit-form">
      <b-form-group label-for="cashback-active" label-class="text-sm" class="w-50 m-0 pr-2 d-inline-block">
        <template #label>{{ $t('general.status') }}<span class="text-danger ml-1">*</span></template>
        <b-form-select
          id="cashback-active"
          v-model="form.is_active"
          :options="[
            { value: 1, text: $t('status.active') },
            { value: 0, text: $t('status.inactive') },
          ]"
          required
          class="w-100">
          <b-form-select-option selected disabled hidden :value="null">{{ $t('input.select_status') }}</b-form-select-option>
        </b-form-select>
      </b-form-group>

      <b-form-group label-for="cashback-currency" label-class="text-sm" class="w-50 m-0 pl-2 d-inline-block">
        <template #label>{{ $t('general.currency') }}<span class="text-danger ml-1">*</span></template>
        <b-form-select id="cashback-currency" value="GBP" required disabled>
          <!-- <b-form-select-option selected disabled hidden :value="null">{{ $t('input.select_currency') }}</b-form-select-option> -->
          <b-form-select-option value="GBP">{{ $t('gbp') }}</b-form-select-option>
          <!-- <b-form-select-option value="MXN">{{ $t('mxn') }}</b-form-select-option> -->
        </b-form-select>
      </b-form-group>

      <b-form-group label-for="cashback-type" label-class="text-sm" class="w-50 mt-3 mb-0 pr-2 d-inline-block">
        <template #label>{{ $t('general.type') }}<span class="text-danger ml-1">*</span></template>
        <b-form-select id="cashback-type" v-model="form.type" required>
          <b-form-select-option selected disabled hidden :value="null">{{ $t('input.select_type') }}</b-form-select-option>
          <b-form-select-option value="weekly">{{ $t('cashback.config_types')[0] }}</b-form-select-option>
          <b-form-select-option value="monthly">{{ $t('cashback.config_types')[1] }}</b-form-select-option>
          <b-form-select-option value="promotion">{{ $t('cashback.config_types')[2] }}</b-form-select-option>
        </b-form-select>
      </b-form-group>
      <b-form-group label-for="cashback-method" label-class="text-sm" class="w-50 mt-3 mb-0 pl-2 d-inline-block">
        <template #label>{{ $t('cashback.calculation_method') }}<span class="text-danger ml-1">*</span></template>
        <b-form-select id="cashback-method" v-model="form.method" required class="w-100">
          <b-form-select-option selected disabled hidden :value="null">{{ $t('cashback.select_calculation') }} </b-form-select-option>
          <b-form-select-option :value="0">{{ $tc('general.amount', 1) }} </b-form-select-option>
          <b-form-select-option :value="1">{{ $t('cashback.rate') }} </b-form-select-option>
        </b-form-select>
      </b-form-group>

      <b-form-group label-for="cashback-target" label-class="text-sm" class="w-50 mt-3 mb-0 pr-2 d-inline-block">
        <template #label>{{ $t('cashback.band') }}<span class="text-danger ml-1">*</span></template>
        <b-form-input id="cashback-target" v-model="form.target_amount" :placeholder="$t('cashback.target_amount')" number type="number" step="0.01" min="0" required />
      </b-form-group>

      <b-form-group v-if="form.method === 0" label-for="cashback-amount" label-class="text-sm" class="w-50 mt-3 mb-0 pl-2 d-inline-block">
        <template #label>{{ $t('cashback.cashback_amount') }}<span class="text-danger ml-1">*</span></template>
        <b-form-input
          :required="form.method === 0"
          id="cashback-amount"
          v-model="form.cashback_amount"
          :placeholder="$t('cashback.cashback_amount')"
          number
          type="number"
          min="0.01"
          step="0.01"
          class="w-100" />
      </b-form-group>
      <b-form-group v-else-if="form.method === 1" label-for="cashback-rate" label-class="text-sm" class="w-50 mt-3 mb-0 pl-2 d-inline-block">
        <template #label>{{ $t('cashback.cashback_rate') }} (%) <span class="text-danger ml-1">*</span></template>
        <b-form-input :required="form.method === 1" id="cashback-rate" v-model="form.cashback_rate" :placeholder="$t('cashback.cashback_rate')" number type="number" step="0.01" min="0.01" max="100" />
      </b-form-group>

      <b-form-group label-for="cashback-comment" label-class="text-sm" class="mt-3 mb-0">
        <template #label>{{ $tc('general.comment', 1) }}</template>
        <b-form-textarea id="cashback-comment" v-model="form.comment" :placeholder="$t('cashback.leave_comment')" rows="5" max-rows="5" />
      </b-form-group>
    </b-form>
    <template #modal-footer="{ hide }">
      <b-button variant="outline-secondary" @click="hide">{{ $t('action.cancel') }}</b-button>
      <b-button variant="outline-primary" type="submit" form="cashback-edit-form">{{ $t('action.submit') }}</b-button>
    </template>
  </b-modal>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'CashbackEdit',
  props: {
    id: String,
    details: Object,
    getList: Function,
  },
  data() {
    return {
      form: {
        id: null,
        is_active: 0,
        currency_code: 'GBP',
        type: null,
        method: null,
        cashback_amount: null,
        cashback_rate: null,
        target_amount: null,
        comment: null,
      },
    }
  },
  computed: {
    ...mapGetters({ selfStore: 'store' }),
  },
  methods: {
    onHide(evt) {
      this.$emit('update:details', null)
      // trigger watcher 'details' --> trigger onReset
    },
    onReset() {
      this.form = {
        id: null,
        is_active: 0,
        currency_code: 'GBP',
        type: null,
        method: null,
        cashback_amount: null,
        cashback_rate: null,
        target_amount: null,
        comment: null,
      }
    },
    onSubmit() {
      if (this.form.method === 0) {
        this.form.cashback_rate = 0
      } else if (this.form.method === 1) {
        this.form.cashback_rate = this.form.cashback_rate / 100
        this.form.cashback_amount = 0
      }
      const params = {
        successMsg: this.$t('cashback.cashback_config_notice[0]', { action: this.form.id ? this.$t('action.update') : this.$t('action.create') }),
        errorMsg: this.$t('cashback.cashback_config_notice[1]', { action: this.form.id ? this.$t('action.update') : this.$t('action.create') }),
      }
      for (const key in this.form) {
        if (key !== 'method' && this.form[key] !== null) {
          params[key] = this.form[key]
        }
      }
      this.$store.dispatch('request/updateCashbackOption', params).then(() => {
        this.getList()
        this.$bvModal.hide('cashback-config-upsert')
      })
    },
  },
  watch: {
    details(newVal, oldVal) {
      if (newVal) {
        this.form = {
          id: newVal.id,
          is_active: newVal.is_active,
          currency_code: newVal.currency_code,
          type: newVal.type,
          method: parseFloat(newVal.cashback_amount) > 0 ? 0 : parseFloat(newVal.cashback_rate) > 0 ? 1 : null,
          cashback_amount: newVal.cashback_amount,
          cashback_rate: newVal.cashback_rate ? newVal.cashback_rate * 100 : null,
          target_amount: newVal.target_amount,
          comment: newVal.comment,
        }
      } else {
        this.onReset()
      }
    },
    'form.method'(val) {
      if (val === 0) {
        this.form.cashback_rate = 0
      } else if (val === 1) {
        this.form.cashback_amount = 0
      } else if (!val) {
        this.form.cashback_amount = 0
        this.form.cashback_rate = 0
      }
    },
  },
}
</script>
