<template>
  <card body-classes="p-0 d-flex flex-column" class="table-custom">
    <loading :active="tbLoading" :can-cancel="false" :is-full-page="false" />
    <div class="px-4 pt-3 pb-3 w-100 d-flex flex-row flex-wrap align-items-start justify-content-between">
      <template v-if="stats">
        <div class="w-50 d-flex flex-column">
          <p v-if="!isProcessing && stats.type == 0" class="mb-0 text-danger">
            ⚠ Please start the upgrade at least 15 minutes before peak hours.
            <b-icon-info-circle-fill v-b-tooltip.hover.topright="'If there are a lot of players placing orders, there might be a few players experiencing issues during the switch.'" class="ml-1" />
          </p>

          <div class="mt-1 w-100 d-flex flex-row align-items-center">
            <div style="width: 216px">Current Database:</div>
            <b-img-lazy :src="`/img/icons/db-conf-${isProcessing ? 'processing' : ['normal', 'upgraded'][stats.type]}.png`" style="width: 24px; height: 24px" class="mx-1" />
            <span :class="['text-success', 'text-danger'][stats.type]"> {{ ['Normal', 'Upgraded'][stats.type] }} Configuration </span>
          </div>

          <div class="mt-1 w-100 d-flex">
            <div style="width: 216px">Database Instance Identifier:</div>
            <div>{{ stats.DBInstanceIdentifier }}</div>
          </div>

          <div class="mt-1 w-100 d-flex">
            <div style="width: 216px">Database Instance Class:</div>
            <div>{{ stats.DBInstanceClass }}</div>
          </div>

          <div class="mt-1 w-100 d-flex">
            <div style="width: 216px">Started At:</div>
            <div>{{ formatLocalDateTime(stats.InstanceCreateTime) }}</div>
          </div>
        </div>

        <div class="w-50 h-100 d-flex flex-column align-items-end justify-content-between">
          <p v-if="!prevExist" class="mb-0 text-danger" :style="isProcessing ? 'opacity: 1' : 'opacity: 0'">
            ⚠ {{ [$t('action.upgrade'), $t('action.downgrade')][stats.type] }} action submitted. {{ isProcessing }} All RKings functionalities remain unaffected. Thank you for your patience.
          </p>
          <p v-if="!isProcessing" class="mb-0 text-danger" :style="prevExist ? 'opacity: 1' : 'opacity: 0'">
            {{ prevExist }}
          </p>

          <b-button @click="upgrade" variant="danger" :disabled="![0, 1].includes(stats.type) || tbLoading || !!isProcessing || existLoading || !!prevExist">
            {{ [$t('action.upgrade'), $t('action.downgrade')][stats.type] + ' Database' }}
          </b-button>
        </div>
      </template>
    </div>

    <b-table
      :fields="fields"
      :items="items"
      :busy="tbLoading"
      show-empty
      :empty-text="$t('notify.table_no_records')"
      caption-top
      small
      hover
      responsive
      class="m-0 p-0"
      head-variant="light"
      primary-key="id"
      sticky-header="100%">
      <template #head()="{ label, field }">
        {{ label }}
        <b-icon-question-circle-fill v-if="field.tooltip" class="ml-1" v-b-tooltip.hover.topright="field.tooltip" />
      </template>
      <template #cell(auto_revert)="{ value }">
        <bool-badge v-if="[0, 1].includes(value)" :value="value" :text="[$t('no'), $t('yes')][value]" />
      </template>
      <template #cell(status)="{ value, item }">
        <b-badge v-if="value != 200" variant="danger" pill>FAILED</b-badge>
        <b-badge v-else-if="item.request" variant="info" pill>SUBMITTED</b-badge>
        <b-badge v-else variant="success" pill>SUCCESS</b-badge>
      </template>
      <template #cell(message)="{ value }"><tooltip-span :content="value" /></template>
    </b-table>
    <paginate :queries="queryParams" :fetcher="getList" :total="history ? history.total : 0" class="card-footer" />

    <confirm-modal
      v-if="stats && [0, 1].includes(stats.type)"
      id="confirm-upgrade"
      :title="[$t('action.upgrade'), $t('action.downgrade')][stats.type] + ' Database Confirmation'"
      :isLoading="isRequesting">
      <template #form>
        <p class="mt-1 mb-0" v-if="stats.type == 0">
          ⚠ If there are a lot of players placing orders, there might be a few players experiencing issues during the switch, so make sure to start the upgrade at least 15 minutes before peak hours.
        </p>
        <p class="mt-1 mb-0">⚠ Are you sure you want to {{ [$t('action.upgrade'), $t('action.downgrade')][stats.type] }} the database?</p>
        <b-form-checkbox v-if="stats.type == 0" id="auto-downgrade" name="auto-downgrade" v-model="autoRevert" :value="1" :unchecked-value="0" class="mt-1">
          Auto downgrade at 23:59:59 p.m. (London time)
        </b-form-checkbox>
      </template>
      <template #footer="{ cancel }">
        <b-button variant="outline-secondary" @click="cancel">Cancel</b-button>
        <b-button variant="outline-primary" @click="confirmUpgrade" :disabled="isRequesting">
          {{ [$t('action.upgrade'), $t('action.downgrade')][stats.type] }}
        </b-button>
      </template>
    </confirm-modal>
  </card>
</template>
<script>
import axios from '@/plugins/axios'
import moment from '@/plugins/moment-timezone'
import { API_LIST } from '@/utils/consts'
import { formatLocalDateTime } from '@/utils/dateTimeUtils'
import { notifyError } from '@/utils/index'
import { notNaN } from '@/utils/numberUtils'
import { mapGetters } from 'vuex'

export default {
  name: 'DatabaseConfig',

  data() {
    const dBInstanceClass = process.env.VUE_APP_AWS_DB_INSTANCE_CLASS?.split?.(',') ?? []
    return {
      existLoading: true,
      prevExist: false,
      statsLoading: true,
      stats: null,
      dBInstanceClass,
      tbLoading: true,
      queryParams: { page: 1, perPage: 10 },
      items: [],
      action: null,
      autoRevert: 1,
      isRequesting: false,
      lastReq: null,
    }
  },

  computed: {
    ...mapGetters({ history: 'request/databaseConfigHistory' }),

    fields() {
      return [
        { key: 'id', sortable: true },
        {
          key: 'request',
          label: this.$t('general.operation'),
          formatter: (v, k, i) => {
            if (v) {
              return v + ' request'
            } else {
              const message = i.message?.toLowerCase?.()
              return message?.includes?.('upgrade') ? 'Upgrade' : message?.includes?.('downgrade') ? 'Downgrade' : ''
            }
          },
          tdClass: 'text-capitalize',
        },
        { key: 'auto_revert', label: 'Auto Downgrade', tooltip: 'Auto downgrade at 23:59:59 p.m. (London time)' },
        { key: 'status', label: this.$t('general.status'), class: 'text-center' },
        { key: 'message', label: 'Response' },
        {
          key: 'admin',
          label: this.$t('general.created_by'),
          formatter: (v) => v?.name ?? v?.email ?? v.id ?? 'System',
          sortable: true,
        },
        {
          key: 'created_at',
          label: this.$t('general.created_at'),
          formatter: (v) => formatLocalDateTime(v),
          sortable: true,
        },
      ]
    },

    isProcessing() {
      const tmp = this.lastReq?.request && this.action && this.lastReq.request == this.action
      if (tmp) {
        const createdAt = moment.utc(this.lastReq.created_at).tz('Europe/London')
        createdAt.add(15, 'minutes')
        const now = moment.utc().tz('Europe/London')
        if (now.isSameOrBefore(createdAt)) {
          return 'Processing operation... Please refresh this page 15 minutes after the request is submitted.'
        } else {
          return 'Operation timeout. Please contact our technical support.'
        }
      } else {
        this.getExist()
        return false
      }
    },
  },

  methods: {
    getExist() {
      if (this.stats && [0, 1].includes(this.stats.type)) {
        this.existLoading = true
        axios
          .get(API_LIST.get.databaseExist, {
            params: { DBInstanceClass: this.dBInstanceClass[this.stats.type == 0 ? 1 : 0] },
          })
          .then((res) => {
            if (res?.data?.data?.exist) {
              const createdAt = moment.utc(this.items[0]?.created_at).tz('Europe/London')
              createdAt.add(15, 'minutes')
              const now = moment.utc().tz('Europe/London')
              if (now.isSameOrBefore(createdAt)) {
                this.prevExist = '⚠ Deleting redundant database instance... Please refresh this page 15 minutes after the previous operation is done.'
              } else {
                this.prevExist = '⚠ Deletion of the redundant database instance timeout. Please contact our technical support.'
              }
            } else {
              this.prevExist = false
            }
          })
          .catch((err) => notifyError(err, this.$t('notify.unknown_err')))
          .finally(() => (this.existLoading = false))
      }
    },

    getStats() {
      this.statsLoading = true
      axios
        .get(API_LIST.get.databaseStats)
        .then((res) => {
          const data = res?.data?.data
          const index = data ? this.dBInstanceClass.findIndex((x) => x == data.DBInstanceClass) : null
          this.stats = data ? { ...data, type: index } : null
          this.$store.dispatch('request/mutateState', { property: 'databaseStats', with: data })
          this.action = index == 0 ? 'upgrade' : index == 1 ? 'downgrade' : null
        })
        .catch((err) => notifyError(err, this.$t('notify.unknown_err')))
        .finally(() => (this.statsLoading = false))
    },

    getList() {
      this.tbLoading = true
      axios
        .get(API_LIST.get.databaseConfigHistory, { params: this.queryParams })
        .then((res) => {
          const data = res?.data?.data
          const items =
            data?.data?.map?.((item) => {
              const params = item.params ? JSON.parse(item.params) : null
              const response = item.response ? JSON.parse(item.response) : null
              return { ...item, request: params?.action, auto_revert: params?.auto_revert, message: response?.message }
            }) || []
          this.items = items
          this.$store.dispatch('request/mutateState', { property: 'databaseConfigHistory', with: data })

          // first page, first item, is request, without response message
          if (data?.current_page == 1) {
            if (items[0]?.request && !items[0].message) {
              this.lastReq = items[0]
            } else {
              this.lastReq = null
            }
          }
        })
        .catch((err) => notifyError(err, this.$t('notify.unknown_err')))
        .finally(() => (this.tbLoading = false))
    },

    upgrade() {
      this.$bvModal.show('confirm-upgrade')
    },

    confirmUpgrade() {
      this.isRequesting = true
      const params = { action: this.action }
      if (this.action == 'upgrade') {
        params['auto_revert'] = this.autoRevert
      }
      axios
        .post(API_LIST.post.databaseConfig, params)
        .catch((err) => notifyError(err, this.$t('notify.unknown_err')))
        .finally(() => {
          this.isRequesting = false
          this.$bvModal.hide('confirm-upgrade')
          this.getStats()
          this.getList()
        })
    },

    formatLocalDateTime,
    notNaN,
  },

  created() {
    this.getStats()
    this.getList()
  },
}
</script>
