<template>
  <card body-classes="p-0 d-flex flex-column" class="table-custom">
    <loading :active="isLoading" :can-cancel="false" :is-full-page="false" />

    <div class="px-4 pt-3 pb-2 d-flex flex-row flex-wrap align-items-start">
      <b-form-select :value="queries.currency" disabled class="mb-2 mr-2" style="width: 58px">
        <b-form-select-option value="GBP">{{ $t('uk') }}</b-form-select-option>
      </b-form-select>
      <b-form-input v-model="queries.id" type="search" :placeholder="$t('general.id')" @keyup.enter="onSearch" class="mb-2 mr-2" style="width: 100px" />
      <b-form-input v-model="queries.customer_token" type="search" :placeholder="$t('transaction.transaction_id')" @keyup.enter="onSearch" class="mb-2 mr-2" style="width: 180px" />
      <b-form-input v-model="queries.name" type="search" :placeholder="$t('general.username')" @keyup.enter="onSearch" class="mb-2 mr-2" style="width: 150px" />
      <b-form-select v-model="queries.method" class="mb-2 mr-2" style="width: 120px">
        <b-form-select-option selected disabled hidden :value="null">{{ $t('payment.payment_method') }}</b-form-select-option>
        <b-form-select-option :value="''">{{ $t('payment.all_methods') }}</b-form-select-option>
        <b-form-select-option v-for="{ value, text } in paymentMethodOpts" :key="value" :value="value">{{ text }}</b-form-select-option>
      </b-form-select>
      <b-form-select v-model="queries['gateway']" class="mb-2 mr-2" style="width: 120px">
        <b-form-select-option :value="null">All Gateway</b-form-select-option>
        <b-form-select-option v-for="{ value, text } in paymentGatewayOpts" :key="value" :value="value">{{ text }}</b-form-select-option>
      </b-form-select>
      <b-form-select v-model="queries.status" class="mb-2 mr-2" style="width: 120px">
        <b-form-select-option selected disabled hidden :value="null">{{ $t('general.status') }}</b-form-select-option>
        <b-form-select-option :value="''">{{ $t('status.all_status') }}</b-form-select-option>
        <b-form-select-option v-for="(opt, index) in ['pending', 'succeeded', 'failed']" :key="'status-opt-' + index" :value="opt" class="text-capitalize">
          {{ $t(`payment.status.${opt}`) }}
        </b-form-select-option>
      </b-form-select>
      <date-range-picker
        v-model="dateRange"
        :locale-data="{ format: 'yyyy-mm-dd' }"
        :auto-apply="true"
        :timePicker24Hour="true"
        :show-dropdowns="true"
        :ranges="ranges"
        class="mb-2 mr-2"
        style="width: max-content; min-width: 220px"
        :max-date="formatLocalDateTime(null, 'YYYY-MM-DD', true, true)"
        v-b-tooltip.hover.top="$t('notify.date_range_notice', { name: $t('general.created_at') })" />

      <button-group :onSearch="onSearch" :onReset="onReset">
        <template #export>
          <b-button variant="reset" @click="onExport" :disabled="isLoading" class="ml-2">
            <b-icon-file-spreadsheet class="mr-1" />{{ isLoading ? $t('status.loading') : $t('action.export') }}
          </b-button>
        </template>
      </button-group>
    </div>

    <b-table
      show-empty
      :empty-text="$t('notify.table_no_records')"
      hover
      responsive
      class="m-0 p-0"
      table-class="topup-pays-table"
      head-variant="light"
      primary-key="id"
      :busy="isLoading"
      :fields="fields"
      :items="items">
      <template #cell(status)="{ value }">
        <b-badge :variant="['primary', 'success', 'danger'][['pending', 'succeeded', 'failed'].indexOf(value)]" class="text-uppercase">
          {{ $t(`payment.status.${value}`) }}
        </b-badge>
      </template>
      <!-- <template #cell(method)="{ value }">
        <template v-if="value != 'card'">
          {{ $t('payment')[['cash_wallet', 'paypal'][['cash', 'paypal'].indexOf(value)]] }}
        </template>
        <template v-else>{{ value }}</template>
      </template> -->
      <template #bottom-row v-if="items.length > 0">
        <td colspan="1" class="font-weight-bold">{{ $t('general.total') }}</td>
        <td colspan="3" class="font-weight-bold text-right">{{ totalPaid }}</td>
        <td colspan="2" class="font-weight-bold text-right">{{ totalReward }}</td>
        <td colspan="4"></td>
      </template>
    </b-table>

    <paginate v-if="items.length > 0" :queries="queries" :fetcher="getList" :total="topupPayments.total" class="card-footer" />
  </card>
</template>
<script>
import { API_LIST } from '@/utils/consts'
import { formatDayEndUtc, formatDayStartUtc, formatLocalDateTime, formatUtcDateTime, setupDateRanges } from '@/utils/dateTimeUtils'
import { exportAsExcel } from '@/utils/fileUtils'
import { formatWithCurrency, sumTotal } from '@/utils/numberUtils'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      isLoading: false,
      ranges: setupDateRanges(),
      paymentMethodOpts: [
        { value: 'card', text: this.$tc('payment.card', 1) },
        { value: 'paypal', text: this.$t('payment.paypal') },
        { value: 'cash', text: this.$t('payment.cash_wallet') },
        { value: 'apple_pay', text: 'Apple Pay' },
        { value: 'google_pay', text: 'Google Pay' },
      ],
      paymentGatewayOpts: [
        { value: 'axcess', text: 'Axcess' },
        { value: 'barclaycard', text: 'Barclay' },
        { value: 'paypal', text: 'PayPal' },
        { value: 'cash_wallet', text: 'RKings' },
      ],
      queries: {
        id: null,
        customer_token: null,
        currency: 'GBP',
        status: null,
        name: null,
        method: null,
        gateway: null,
        start_time: null,
        end_time: null,
        page: 1,
        perPage: 10,
        ...this.$route.params?.prev,
      },
      fields: [
        { key: 'id', label: this.$t('general.id'), sortable: true, thStyle: 'background-position-x: 2.6rem !important' },
        { key: 'customer_token', label: this.$t('transaction.transaction_id'), formatter: (v) => (v ? v : this.$t('no_data')) },
        { key: 'user.name', label: this.$t('general.username') },
        {
          key: 'amount_ordered',
          label: this.$tc('general.amount', 1),
          sortable: true,
          formatter: (v, k, i) => formatWithCurrency(v, i.currency_code),
          class: 'text-right',
        },
        { key: 'rate', label: this.$t('topup.rate'), formatter: (v) => v + '%', class: 'text-right' },
        { key: 'reward', label: this.$t('topup.reward_amount'), formatter: (v, k, i) => formatWithCurrency(v, i.currency_code), class: 'text-right' },
        { key: 'gateway', tdClass: 'text-capitalize', formatter: (v) => (v?.includes('cash_wallet') ? 'RKings' : v?.includes('barclay') ? 'Barclay' : v) },
        {
          key: 'method',
          label: this.$t('payment.payment_method'),
          tdClass: 'text-capitalize',
          formatter: (v) => (v?.includes('cash') ? this.$t('payment.cash_wallet') : v?.includes('apple') ? 'Apple Pay' : v?.includes('google') ? 'Google Pay' : v),
        },
        { key: 'status', label: this.$t('general.status'), sortable: true, thStyle: 'background-position-x: 4.5rem !important' },
        {
          key: 'created_at',
          label: this.$t('general.created_at'),
          sortable: true,
          formatter: (v) => formatLocalDateTime(v),
          thStyle: 'background-position-x: 6.2rem !important',
        },
        {
          key: 'updated_at',
          label: this.$t('general.updated_at'),
          sortable: true,
          formatter: (v) => formatLocalDateTime(v),
          thStyle: 'background-position-x: 6.35rem !important',
        },
      ],
      actionTarget: null,
    }
  },
  computed: {
    ...mapGetters({ topupPayments: 'request/topupPayments', selfStore: 'store' }),
    items() {
      const tmp = this.topupPayments ? this.topupPayments.data.filter((x) => x.id) : []
      return tmp
    },
    totalPaid() {
      const { total_paid } = this.topupPayments ? this.topupPayments.data[this.topupPayments.data.length - 1] : {}
      const tmp = sumTotal(this.items, 'amount_ordered')
      return formatWithCurrency(tmp, 'GBP') + ' / ' + formatWithCurrency(total_paid, 'GBP')
    },
    totalReward() {
      const { total_reward } = this.topupPayments ? this.topupPayments.data[this.topupPayments.data.length - 1] : {}
      const tmp = sumTotal(this.items, 'reward')
      return formatWithCurrency(tmp, 'GBP') + ' / ' + formatWithCurrency(total_reward, 'GBP')
    },
    dateRange: {
      get() {
        return {
          startDate: formatLocalDateTime(this.queries.start_time, 1, false, true),
          endDate: formatLocalDateTime(this.queries.end_time, 1, false, true),
        }
      },
      set(newVal) {
        this.queries['start_time'] = formatDayStartUtc(newVal.startDate, 0, false, true)
        this.queries['end_time'] = formatDayEndUtc(newVal.endDate, 0, false, true)
        return newVal
      },
    },
  },
  methods: {
    getList() {
      this.isLoading = true
      const params = { errorMsg: this.$t('payment.search_failed') }
      for (const key in this.queries) {
        if (this.queries[key]) {
          params[key] = this.queries[key]
        }
      }
      this.$store
        .dispatch('request/getTopupPayments', params)
        .then(() => (this.isLoading = false))
        .catch(() => (this.isLoading = false))
    },
    onSearch() {
      this.queries.page = 1
      this.getList()
    },
    onReset(e) {
      // e.preventDefault()
      this.queries.id = null
      this.queries.customer_token = null
      this.queries.currency = 'GBP'
      this.queries.status = null
      this.queries.name = null
      this.queries.method = null
      this.queries.gateway = null
      this.dateRange['startDate'] = null
      this.dateRange['endDate'] = null
      this.queries.start_time = null
      this.queries.end_time = null
      this.onSearch()
    },
    csvPreProcess(data) {
      if (data[data.length - 1].total_paid !== undefined) data.splice(data.length - 1, 1) // remove last row which only has total_paid and total_reward
      for (const i in data) {
        data[i].username = data[i].user.name
        data[i].topup_amount = formatWithCurrency(data[i].amount_ordered, data[i].currency_code)
        for (const j in data[i]) {
          if (['id', 'customer_token', 'username', 'topup_amount', 'method', 'status', 'created_at', 'updated_at'].indexOf(j) == -1) {
            delete data[i][j]
          } else if (typeof data[i][j] !== 'number' && !data[i][j]) {
            data[i][j] = this.$t('no_data')
          }
        }
      }
    },
    async onExport() {
      return exportAsExcel(
        this.$t('payment.topup_pays_filename') + '_' + formatUtcDateTime(undefined, 2, true),
        API_LIST.get.topupPayments,
        { ...this.queries, page: 1, perPage: 1000 },
        this.topupPayments.total,
        this.csvPreProcess,
        ['id', 'customer_token', 'username', 'topup_amount', 'method', 'status', 'created_at', 'updated_at'],
        [
          [
            this.$t('general.id'),
            this.$t('transaction.transaction_id'),
            this.$t('general.username'),
            this.$tc('general.amount', 1),
            this.$t('payment.payment_method'),
            this.$t('general.status'),
            this.$t('general.created_at'),
            this.$t('general.updated_at'),
          ],
        ],
        (bool) => (this.isLoading = bool)
      )
    },
    formatLocalDateTime,
  },
  created() {
    this.getList()
  },
  beforeRouteLeave(to, from, next) {
    this.$set(from.params, 'preservedParams', this.queries)
    return next()
  },
}
</script>
