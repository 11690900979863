<template>
  <b-modal
    lazy
    centered
    id="topup-edit"
    :title="details ? $t('topup.edit_topup_config') : $t('topup.create_topup_config')"
    title-class="w-100 d-flex justify-content-center align-self-center"
    header-class="py-2"
    body-class="py-0"
    footer-class="py-2">
    <b-form @submit.prevent="onSubmit" id="topup-edit-form">
      <b-form-group label-for="topup-active" label-class="text-sm" class="w-25 m-0 pr-2 d-inline-block">
        <template #label>{{ $t('general.status') }}<span class="text-danger ml-1">*</span></template>
        <b-form-select
          id="topup-active"
          v-model="form.is_active"
          :options="[
            { value: 1, text: $t('status.active') },
            { value: 0, text: $t('status.inactive') },
          ]"
          required
          class="w-100"
          ><b-form-select-option selected disabled hidden :value="null">{{ $t('input.select_status') }}</b-form-select-option>
        </b-form-select>
      </b-form-group>
      <b-form-group label-for="topup-currency" label-class="text-sm" class="w-25 m-0 pl-2 d-inline-block">
        <template #label>{{ $t('general.currency') }}<span class="text-danger ml-1">*</span></template>
        <b-form-select id="topup-currency" :value="form.currency_code" required disabled>
          <!-- <b-form-select-option selected disabled hidden :value="null">{{ $t('input.select_currency') }}</b-form-select-option> -->
          <b-form-select-option value="GBP">{{ $t('gbp') }}</b-form-select-option>
          <!-- <b-form-select-option value="MMX">{{ $t('mxn') }}</b-form-select-option> -->
        </b-form-select>
      </b-form-group>
      <b-form-group label-for="topup-reward-rate" label-class="text-sm" class="w-50 m-0 pl-4 d-inline-block">
        <template #label>{{ $t('topup.calculation_method') }}<span class="text-danger ml-1">*</span></template>
        <b-form-select id="topup-reward-rate" v-model="form.reward_type" required class="w-100">
          <b-form-select-option selected disabled hidden :value="null">{{ $t('topup.select_calculation') }}</b-form-select-option>
          <b-form-select-option :value="0">{{ $tc('general.amount', 1) }}</b-form-select-option>
          <b-form-select-option :value="1">{{ $t('topup.rate') }}</b-form-select-option>
        </b-form-select>
      </b-form-group>
      <div class="mt-3 w-100 d-flex flex-row">
        <b-form-group label-for="topup-payable-amount" label-class="text-sm" class="w-50 m-0 d-inline-block">
          <template #label>{{ $t('topup.payable_amount') }}<span class="text-danger ml-1">*</span></template>
          <b-form-input id="topup-payable-amount" v-model="form.payable_amount" :placeholder="$t('topup.payable_amount')" number type="number" step="0.01" min="0" required />
        </b-form-group>
        <b-form-group v-if="form.reward_type === 0" label-for="topup-reward-amount" label-class="text-sm" class="w-50 m-0 pl-4 d-inline-block">
          <template #label>{{ $t('topup.reward_amount') }}<span class="text-danger ml-1">*</span></template>
          <b-form-input
            :required="form.reward_type === 0"
            id="topup-reward-amount"
            v-model="form.reward_amount"
            :placeholder="$t('topup.reward_amount')"
            number
            type="number"
            min="0.01"
            step="0.01"
            class="w-100" />
        </b-form-group>
        <b-form-group v-else-if="form.reward_type === 1" label-for="topup-reward-rate" label-class="text-sm" class="w-50 m-0 pl-4 d-inline-block">
          <template #label>{{ $t('topup.reward_rate') }} (%) <span class="text-danger">*</span></template>
          <b-form-input
            :required="form.reward_type === 1"
            id="topup-reward-rate"
            v-model="form.reward_rate"
            :placeholder="$t('topup.reward_rate')"
            number
            type="number"
            step="0.01"
            min="0.01"
            max="100" />
        </b-form-group>
      </div>
      <!-- <b-form-group id="topup-start" label-class="text-sm" label-for="topup-start-time" class="w-50 mt-3 mb-0 d-inline-block">
      <template #label>
        <p class="mb-0 font-weight-bold" style="font-size: 0.65rem">Start Date</p>
      </template>
      <b-form-input id="topup-start-time" v-model="form.start_time" :state="dateValidation" type="datetime-local" placeholder="Choose a date" />
    </b-form-group>
    <b-form-group id="topup-end" label-class="text-sm" label-for="topup-end-time" class="w-50 mt-3 mb-0 pl-4 d-inline-block">
      <template #label>
        <p class="mb-0 font-weight-bold" style="font-size: 0.65rem">End Date</p>
      </template>
      <b-form-input id="topup-end-time" v-model="form.end_time" :state="dateValidation" type="datetime-local" placeholder="Choose a date" />
    </b-form-group>
    <b-form-invalid-feedback :state="dateValidation">{{ $t('productDateValidate_False') }} </b-form-invalid-feedback> -->
      <b-form-group label-for="topup-comment" label-class="text-sm" class="mt-3 mb-0">
        <template #label>{{ $tc('general.comment', 1) }}</template>
        <b-form-textarea id="topup-comment" v-model="form.comment" :placeholder="$t('cashback.leave_comment')" rows="5" max-rows="5" />
      </b-form-group>
    </b-form>
    <template #modal-footer="{ hide }">
      <b-button variant="outline-secondary" @click="hide">{{ $t('action.cancel') }}</b-button>
      <b-button variant="outline-primary" type="submit" form="topup-edit-form">{{ $t('action.submit') }}</b-button>
    </template>
  </b-modal>
</template>
<script>
export default {
  name: 'TopupEdit',
  props: {
    details: Object,
    getList: Function,
  },
  data() {
    return {
      form: {
        id: null,
        currency_code: 'GBP',
        payable_amount: null,
        reward_type: null,
        reward_amount: null,
        reward_rate: null,
        start_time: null,
        end_time: null,
        is_active: null,
        comment: null,
      },
    }
  },
  methods: {
    onReset() {
      this.form = {
        id: null,
        currency_code: 'GBP',
        payable_amount: null,
        reward_type: null,
        reward_amount: null,
        reward_rate: null,
        start_time: null,
        end_time: null,
        is_active: null,
        comment: null,
      }
    },
    onSubmit() {
      if (this.form.reward_type === 0) {
        this.form.reward_rate = 0
      } else if (this.form.reward_type === 1) {
        this.form.reward_rate = this.form.reward_rate / 100
        this.form.reward_amount = 0
      }
      const params = {
        successMsg: this.form.id ? this.$t('topup.topup_config_notice[0]', { action: this.$t('action.update') }) : this.$t('topup.topup_config_notice[0]', { action: this.$t('action.update') }),
        errorMsg: this.form.id ? this.$t('topup.topup_config_notice[1]', { action: this.$t('action.update') }) : this.$t('topup.topup_config_notice[1]', { action: this.$t('action.update') }),
      }
      for (const key in this.form) {
        if (key !== 'reward_type' && this.form[key] !== null) {
          params[key] = this.form[key]
        }
      }
      this.$store.dispatch('request/updateTopup', params).then((res) => {
        if (res.status === 200) {
          this.getList(this.queryParams)
          this.$bvModal.hide('topup-edit')
        }
      })
    },
  },
  watch: {
    details(newVal, oldVal) {
      if (newVal) {
        this.form = {
          id: newVal.id,
          is_active: newVal.is_active,
          currency_code: newVal.currency_code,
          type: newVal.type,
          reward_type: parseFloat(newVal.reward_amount) > 0 ? 0 : parseFloat(newVal.reward_rate) > 0 ? 1 : null,
          reward_amount: newVal.reward_amount,
          reward_rate: newVal.reward_rate ? newVal.reward_rate * 100 : null,
          payable_amount: newVal.payable_amount,
          comment: newVal.comment,
        }
      } else {
        this.onReset()
      }
    },
    'form.reward_type'(val) {
      if (!val) {
        this.form.reward_amount = null
        this.form.reward_rate = null
      } else if (val === 0) {
        this.form.reward_rate = null
      } else if (val === 1) {
        this.form.reward_amount = null
      }
    },
  },
}
</script>
