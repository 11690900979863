<template>
  <card body-classes="p-0 d-flex flex-column" class="table-custom">
    <loading :active="isLoading" :can-cancel="false" :is-full-page="false" />
    <div class="px-4 pt-3 pb-3 w-100 d-flex flex-row flex-wrap align-items-start justify-content-end">
      <b-button @click="onUpsert(null)" variant="basic"><b-icon-plus class="mr-1" />{{ $t('coupon.create_new_coupon') }}</b-button>
    </div>
    <b-table :fields="fields" :items="items" show-empty :empty-text="$t('notify.table_no_records')" small hover responsive head-variant="light" class="m-0 p-0" sticky-header="100%">
      <template #cell(is_active)="{ value }">
        <bool-badge :value="value" :text="value == 1 ? $t('status.active') : $t('status.inactive')" />
      </template>
      <template #cell(id)="{ value, item }">
        <b-button @click="$router.push({ name: 'Coupon Code List', query: { id: value, status: item.is_active, title: encodeURIComponent(item.title) } })">
          <b-icon-list-ul class="mr-1" />{{ $t('coupon.code_list') }}
        </b-button>
        <b-button @click="onUpsert(item)" :disabled="item.coupon_codes.length !== 0"><b-icon-pencil v-b-tooltip.hover.top="$t('action.edit')" /></b-button>
        <b-button @click="onDelete(value)" :disabled="item.coupon_codes.length !== 0"><b-icon-trash v-b-tooltip.hover.topright="$t('action.delete')" /></b-button>
      </template>
      <template #cell(competitions)="{ value }">
        <template v-if="value.length > 0">
          <router-link
            v-for="(comp, index) in value"
            :key="'coupon-comp-' + index"
            :to="{ name: 'Competition Details', query: { id: comp.id }, params: { competition: comp } }"
            target="_blank"
            v-b-tooltip.hover.right="comp.title"
            :class="`text-decoration-none action d-block w-auto text-truncate${index == 0 ? '' : ' mt-2 pt-2 border-top'}`">
            {{ comp.title }}
          </router-link>
        </template>
      </template>
    </b-table>
    <paginate v-if="items.length > 0" :queries="queries" :fetcher="getList" :total="coupons.total" class="card-footer" />
    <CouponUpsert v-if="items.length > 0" :coupon="actionTarget" :getList="getList" />
  </card>
</template>
<script>
import CouponUpsert from '@/components/Coupon/CouponUpsert.vue'
import { formatLocalDateTime } from '@/utils/dateTimeUtils'
import { formatWithCurrency } from '@/utils/numberUtils'
import { mapGetters } from 'vuex'

export default {
  components: { CouponUpsert },
  data() {
    return {
      isLoading: false,
      queries: {
        id: null,
        title: null,
        type: null,
        is_active: null,
        perPage: 10,
        page: 1,
      },
      actionTarget: null,
      fields: [
        { key: 'title', label: this.$t('coupon.coupon_title') },
        { key: 'is_active', label: this.$t('general.status'), class: 'text-center' },
        { key: 'type', label: this.$t('general.type'), formatter: (v) => this.$t(`topup.${v}`), class: 'text-uppercase' },
        {
          key: 'amount',
          label: this.$t('coupon.value'),
          formatter: (v, k, i) => (i.type === 'rate' ? v * 100 + '%' : v && v > 0 ? formatWithCurrency(v, i.currency_code) : this.$t('no_data')),
          sortable: true,
          class: 'text-right',
        },
        { key: 'competitions', label: 'Competition(s)' },
        // { key: 'expire_days', label: this.$t('coupon.expire_days'), class: 'text-right' },
        { key: 'expired_at', label: this.$t('coupon.expire_at'), formatter: (v) => formatLocalDateTime(v), sortable: true },
        { key: 'id', label: this.$tc('general.action', 1), class: 'text-center px-2' },
        { key: 'created_at', label: this.$t('general.created_at'), formatter: (v) => formatLocalDateTime(v), sortable: true },
      ],
    }
  },
  computed: {
    ...mapGetters({ coupons: 'request/couponList' }),
    items() {
      return this.coupons?.data ?? []
    },
  },
  methods: {
    getList(_params) {
      this.isLoading = true
      const params = { ...this.queries, ..._params }
      this.$store
        .dispatch('request/getCouponList', params)
        .then(() => (this.isLoading = false))
        .catch(() => (this.isLoading = false))
    },
    onUpsert(row) {
      this.actionTarget = row
      this.$bvModal.show(`coupon-upsert`)
    },
    onDelete(id) {
      this.$store
        .dispatch('request/deleteCoupon', {
          id: id,
          successMsg: this.$t('notifications.coupon_delete_success'),
          errorMsg: this.$t('notifications.coupon_delete_error'),
        })
        .then((res) => {
          if (res.status === 200) this.getList()
        })
    },
  },
  created() {
    this.getList()
  },
}
</script>
