<template>
  <b-modal
    lazy
    centered
    id="upsert-donation"
    :title="`${form.id ? $t('donation.upsert_donation', { action: $t('action.edit') }) : $t('donation.upsert_donation', { action: $t('action.create') })}`"
    title-class="w-100 d-flex justify-content-center align-self-center"
    header-class="py-2"
    footer-class="py-2"
    body-class="py-0"
    @shown="onShown"
    @hidden="onHidden"
    :busy="isLoading"
    ><loading :active.sync="isLoading" :can-cancel="false" :is-full-page="false" />
    <b-form @submit.prevent="onSubmit" id="donation-upsert-form">
      <b-form-group label-for="donation-title" label-class="text-sm" class="d-inline-block w-50 my-0">
        <template #label>{{ $t('donation.donation_title') }}<span class="text-danger ml-1">*</span></template>
        <b-form-input id="donation-title" v-model="form.title" type="text" :placeholder="$t('donation.input_title')" required />
      </b-form-group>
      <b-form-group label-for="donation-status" label-class="text-sm" class="d-inline-block w-25 ml-3 my-0">
        <template #label>{{ $t('coupon.active_status') }}<span class="text-danger ml-1">*</span></template>
        <b-form-checkbox id="donation-status" v-model="form.is_active" :value="1" :unchecked-value="0" required switch />
      </b-form-group>
      <b-form-group label-for="donation-desc" label-class="text-sm" class="mt-3 mb-0">
        <template #label>{{ $t('donation.donation_description') }}<span class="text-danger ml-1">*</span></template>
        <b-form-textarea id="donation-desc" v-model="form.description" :placeholder="$t('donation.input_description')" rows="5" no-resize required style="font-size: 0.85rem" />
      </b-form-group>
      <b-form-group label-for="donation-image" label-class="text-sm" class="mt-3 mb-0">
        <template #label>{{ $t('donation.donation_image') }}<span class="text-danger ml-1">*</span></template>
        <b-form-input v-model="form.image_url" type="url" required :disabled="form.image_url !== null" class="mt-2" :placeholder="$t('image.url_notice')" />
        <b-input-group size="sm" class="mt-2">
          <b-form-file accept="image/*" id="donation-image" v-model="file" :placeholder="$t('input.no_file_chosen')" :browse-text="$t('input.choose_file')" class="text-truncate h-100" />
        </b-input-group>
        <p class="mt-1 mb-0 text-sm">⚠ {{ $t('image.ratio_notice') }}</p>
        <p class="mt-1 mb-0 text-sm">⚠ {{ $t('image.size_notice') }}</p>
        <p class="mt-1 text-sm" v-if="hasImage">⚠ {{ $t('image.replace_notice') }}</p>
        <b-table
          v-if="hasImage"
          :fields="fields"
          :items="imageArr"
          :busy="isLoading"
          show-empty
          :empty-text="$t('notify.table_no_records')"
          small
          hover
          responsive
          head-variant="light"
          class="m-0 p-0">
          <template #cell(url)="{ value }">
            <span v-b-tooltip.hover.right="value">{{ value }}</span>
          </template>
          <template #cell(preview)="{ item }">
            <b-img-lazy :src="item.url" fluid block rounded style="max-height: 3rem" />
          </template>
          <template #cell(uploaded)="{ value }">
            <b-button variant="outline-primary" size="xs" @click="uploadFile()" :disabled="value || file['size'] >= 614400"><b-icon-upload /></b-button>
            <b-button variant="outline-danger" size="xs" @click="clearFile()" :disabled="value"><b-icon-trash /></b-button>
          </template>
        </b-table>
      </b-form-group>
    </b-form>
    <template #modal-footer="{ cancel }">
      <b-button variant="outline-secondary" @click="cancel">{{ $t('action.cancel') }}</b-button>
      <b-button variant="outline-primary" type="submit" form="donation-upsert-form" :disabled="!isReady">{{ $t('action.submit') }}</b-button>
    </template>
  </b-modal>
</template>
<script>
import { AwsUpdate } from '@/store/services/aws'
import { base64Encode } from '@/utils/fileUtils'
import { notifyError } from '@/utils/index'
import { formatBytes } from '@/utils/numberUtils'

export default {
  name: 'DonationUpsert',
  props: {
    donation: Object,
  },
  data() {
    const initialForm = { title: '', description: '', image_url: '', is_active: false }
    return {
      form: { ...initialForm },
      initialForm: { ...initialForm },
      file: null,
      imageArr: [],
      isLoading: false,
    }
  },
  computed: {
    hasImage() {
      return this.file || this.form.image_url
    },
    isReady: {
      get() {
        return this.form.title.length > 0 && this.form.description.length > 0 && this.form.image_url.length > 0
      },
      set(v) {
        return v
      },
    },
    fields() {
      return [
        { key: 'url', tdClass: 'text-truncate' },
        { key: 'preview', label: this.$t('general.preview') },
        { key: 'size', label: this.$t('general.size') + '(<600kb)', formatter: (v) => formatBytes(v), class: 'text-danger' },
        { key: 'uploaded', label: this.$tc('general.action', 1) },
      ]
    },
  },
  watch: {
    file(newVal, oldVal) {
      if (newVal !== oldVal) {
        const len = this.imageArr.length
        if (newVal) {
          base64Encode(newVal).then((value) => {
            const tmp = { size: newVal.size, url: value, uploaded: false }
            if (!oldVal && len < 2) {
              this.imageArr.push(tmp)
            } else {
              this.imageArr.splice(len - 1, 1, tmp)
            }
          })
        } else {
          this.imageArr.splice(len - 1, 1)
        }
      }
    },
  },
  methods: {
    clearFile() {
      this.file = null
    },
    uploadFile() {
      if (!this.file) {
        this.$notify({ group: 'root', type: 'warn', text: this.$t('image.file_notice') })
        return
      } else {
        this.isLoading = true
        AwsUpdate('rk-donations', this.file)
          .then((res) => {
            this.isLoading = false
            if (res && res.Location) {
              this.form.image_url = res.Location
              if (this.imageArr[this.imageArr.length - 1]) {
                this.imageArr[this.imageArr.length - 1]['url'] = res.Location
                this.imageArr[this.imageArr.length - 1]['uploaded'] = true
              }
              this.$notify({ group: 'root', type: 'success', title: 'Success', text: this.$t('image.upload_notice[0]') })
            } else {
              this.$notify({ group: 'root', type: 'error', title: 'Error', text: this.$t('notify.unknown_err') })
            }
          })
          .catch((err) => notifyError(err, this.$t('notify.unknown_err')))
      }
    },
    onShown() {
      if (this.donation) {
        this.form = this.donation
        if (this.donation.image_url) {
          this.imageArr = [{ url: this.donation.image_url, size: NaN, uploaded: true }]
        }
      }
      this.isLoading = false
    },
    onSubmit() {
      this.isLoading = true
      const params = {
        ...this.form,
        successMsg: this.form.id
          ? this.$t('donation.upsert_donation_notice[0]', { action: this.$t('action.update') })
          : this.$t('donation.upsert_donation_notice[0]', { action: this.$t('action.create') }),
        errorMsg: this.form.id
          ? this.$t('donation.upsert_donation_notice[1]', { action: this.$t('action.update') })
          : this.$t('donation.upsert_donation_notice[1]', { action: this.$t('action.create') }),
      }
      this.$store
        .dispatch('request/upsertDonation', params)
        .then(() => {
          this.isLoading = false
          this.$bvModal.hide('upsert-donation')
        })
        .catch(() => (this.isLoading = false))
    },
    onHidden() {
      this.isLoading = true
      this.form = { ...this.initialForm }
      this.$emit('update:donation', null)
    },
  },
}
</script>
