<template>
  <card body-classes="p-0 d-flex flex-column" class="table-custom">
    <loading :active="isLoading" :can-cancel="false" :is-full-page="false" />

    <b-table
      :fields="fields"
      :items="cardsItems"
      :busy="isLoading"
      show-empty
      :empty-text="$t('notify.table_no_records')"
      caption-top
      small
      hover
      responsive
      class="m-0 p-0"
      head-variant="light"
      primary-key="id"
      :caption="$tc('payment.card', 1)">
      <template #head(value)="{ label }"> {{ label }}<b-icon-question-circle-fill class="ml-2" v-b-tooltip.hover.left="$t('payment.switch_notice')" /></template>
      <template #cell(value)="data">
        <b-form-checkbox v-model="data.value" :value="1" :unchecked-value="0" switch @change="onToggle(data.item, $event, data.index == 0 ? 1 : 0)" />
      </template>
    </b-table>

    <b-table
      :fields="fields"
      :items="paypalItems"
      :busy="isLoading"
      show-empty
      :empty-text="$t('notify.table_no_records')"
      caption-top
      small
      hover
      responsive
      class="m-0 p-0"
      head-variant="light"
      primary-key="id"
      :caption="$t('payment.paypal')">
      <template #cell(value)="data">
        <b-form-checkbox v-model="data.value" :value="1" :unchecked-value="0" switch @change="onToggle(data.item, $event)" />
      </template>
    </b-table>
    <div v-if="tabConfigs" class="card-footer"></div>
  </card>
</template>
<script>
import routerMixin from '@/mixins/router-mixin'
import { formatLocalDateTime } from '@/utils/dateTimeUtils'
import { mapGetters } from 'vuex'

export default {
  name: 'TabConfig',
  mixins: [routerMixin],
  data() {
    return {
      isLoading: false,
    }
  },
  computed: {
    ...mapGetters({ tabConfigs: 'request/tabConfigs' }),
    fields() {
      return [
        { key: 'id', label: this.$t('general.id') },
        { key: 'key', label: this.$t('payment.key'), tdClass: 'text-capitalize font-weight-bold text-primary' },
        { key: 'updated_at', label: this.$t('general.updated_at'), formatter: (v) => formatLocalDateTime(v) },
        this.hasPermission(109) ? { key: 'value', label: this.$t('action.toggle') } : null,
      ]
    },
    cards() {
      return this.tabConfigs?.children?.find((x) => x.key == 'payment')?.children?.filter((x) => x.key != 'paypal')
    },
    cardsItems() {
      return this.cards ?? []
    },
    paypal() {
      return this.tabConfigs?.children?.find((x) => x.key == 'payment')?.children?.find((x) => x.key == 'paypal')
    },
    paypalItems() {
      const tmp = []
      if (this.paypal) {
        tmp.push(this.paypal)
      }
      return tmp
    },
  },
  methods: {
    getList() {
      this.isLoading = true
      this.$store
        .dispatch('request/getTabConfigs', { id: 1 })
        .then(() => (this.isLoading = false))
        .catch(() => (this.isLoading = false))
    },
    onToggle(row, evt, other) {
      this.isLoading = true
      const params = { ...row, value: evt, successMsg: this.$t('payment.edit_config_notice[0]'), errorMsg: this.$t('payment.edit_config_notice[1]') }
      delete params.created_at
      delete params.updated_at
      this.$store
        .dispatch('request/editSysConfigs', params)
        .then(() => {
          if (evt == 1 && this.cardsItems[other].value == 1) {
            const otherParams = { ...this.cardsItems[other], value: 0 }
            delete otherParams.created_at
            delete otherParams.updated_at
            this.$store.dispatch('request/editSysConfigs', otherParams).then(() => {
              this.isLoading = false
              this.getList()
            })
          } else {
            this.isLoading = false
            this.getList()
          }
        })
        .catch(() => (this.isLoading = false))
    },
  },
  created() {
    this.getList()
  },
}
</script>
<style>
.b-table > caption {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}
</style>
