<template>
  <card body-classes="p-0 d-flex flex-column" class="table-custom">
    <loading :active="isLoading" :can-cancel="false" :is-full-page="false"></loading>
    <div class="px-4 pt-3 pb-2 d-flex flex-row flex-wrap align-items-start">
      <b-form-input v-model="queryParams['action']" type="search" @keyup.enter="onSearch" placeholder="API path" class="mb-2 mr-2" style="width: 180px" />
      <b-form-input v-model="queryParams['name']" type="search" @keyup.enter="onSearch" placeholder="Name" class="mb-2 mr-2" style="width: 160px" />
      <date-range-picker
        v-model="dateRange"
        :locale-data="{ format: 'yyyy-mm-dd' }"
        :auto-apply="true"
        :timePicker24Hour="true"
        :show-dropdowns="true"
        :ranges="ranges"
        class="mb-2 mr-2"
        style="width: max-content; min-width: 220px"
        :max-date="formatLocalDateTime(null, 'YYYY-MM-DD', true, true)"
        v-b-tooltip.hover.top="$t('notify.date_range_notice', { name: $t('general.created_at') })" />
      <button-group :onSearch="onSearch" :onReset="onReset" class="mb-2" />
    </div>
    <b-table
      :fields="fields"
      :items="items"
      @row-clicked="rowClicked"
      show-empty
      :empty-text="$t('notify.table_no_records')"
      small
      striped
      hover
      responsive
      head-variant="light"
      class="m-0 p-0"
      primary-key="id">
      <template #head()="{ label, field }">{{ label }}<b-icon-question-circle-fill v-if="field.tooltip" class="ml-1" v-b-tooltip.hover.topright="field.tooltip" /></template>
      <template #cell(status)="{ value }"><http-status-badge :value="value" /></template>
    </b-table>
    <paginate v-if="items.length > 0" :queries="queryParams" :fetcher="getList" :total="actionLogs.total" class="card-footer" />

    <b-modal
      lazy
      centered
      id="action-log-details"
      :title="$t('admin.action_log_details') + (actionTarget ? ' #' + actionTarget.id : '')"
      title-class="w-100 d-flex justify-content-center align-self-center"
      header-class="py-2"
      footer-class="py-2"
      body-class="py-0"
      ok-only
      ok-variant="outline-secondary"
      :ok-title="$t('action.close')"
      @hidden="actionTarget = null">
      <template v-if="actionTarget">
        <div
          v-for="({ label, value }, index) in [
            { label: $t('general.name'), value: actionTarget.admin.name },
            { label: $t('general.email'), value: actionTarget.admin.email },
            { label: $t('general.created_at'), value: formatLocalDateTime(actionTarget.created_at) },
            { label: $t('general.updated_at'), value: formatLocalDateTime(actionTarget.updated_at) },
            { label: $tc('general.action', 1), value: actionTarget.action },
          ]"
          :key="'details-' + index"
          :class="index === 0 ? '' : 'mt-3'">
          {{ label }}:<span class="ml-2 text-primary font-weight-bold">{{ value }}</span>
          <http-status-badge v-if="index == 4" :value="actionTarget.status" class="ml-1" />
        </div>

        <b-icon
          :icon="isDarkTheme ? 'sun' : 'moon'"
          class="position-absolute text-xl"
          style="right: 1.5rem; top: 10rem"
          @click="isDarkTheme = !isDarkTheme"
          v-b-tooltip.hover.left="`Toggle theme (Dark or light)`" />
        <b-card no-body class="my-2" header-class="d-flex py-2">
          <template #header>
            <span v-b-toggle.params-card class="flex-grow-1">Params</span>
            <b-icon v-b-toggle.params-card :icon="showParams ? 'dash' : 'plus'" class="text-xl" />
          </template>
          <b-collapse id="params-card" v-model="showParams">
            <b-card-body :class="`${isDarkTheme ? 'bg-dark' : 'bg-white'} pt-2`">
              <pre :class="`${isDarkTheme ? 'text-white' : 'text-dark'}`">{{ actionParams }}</pre>
            </b-card-body>
          </b-collapse>
        </b-card>
        <b-card no-body class="my-2" header-class="d-flex py-2">
          <template #header>
            <span v-b-toggle.res-card class="flex-grow-1">Response</span>
            <b-icon v-b-toggle.res-card :icon="showResponse ? 'dash' : 'plus'" class="text-xl" />
          </template>
          <b-collapse id="res-card" v-model="showResponse">
            <b-card-body :class="`${isDarkTheme ? 'bg-dark' : 'bg-white'} pt-2`">
              <pre :class="`${isDarkTheme ? 'text-white' : 'text-dark'}`">{{ actionResponse }}</pre>
            </b-card-body>
          </b-collapse>
        </b-card>
      </template>
    </b-modal>
  </card>
</template>
<script>
import { formatDayEndUtc, formatDayStartUtc, formatLocalDateTime, setupDateRanges } from '@/utils/dateTimeUtils'
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      isLoading: true,
      queryParams: {
        action: null,
        name: null,
        start: null,
        end: null,
        page: 1,
        perPage: 10,
        ...this.$route.params?.prev,
      },
      ranges: setupDateRanges(),
      actionTarget: null,
      isDarkTheme: true,
      showParams: true,
      showResponse: true,
    }
  },
  computed: {
    ...mapGetters({ actionLogs: 'request/actionLogs' }),
    fields() {
      return [
        {
          key: 'action',
          label: 'API',
          tooltip: this.$t('notify.click_for', { name: this.$t('admin.action_log_details') }),
          tdClass: 'text-primary font-weight-bold',
          tdAttr: { style: 'cursor:pointer' },
        },
        { key: 'status', label: this.$t('general.status') },
        { key: 'admin.name', label: this.$t('general.admin'), tdAttr: { style: 'cursor:pointer' } },
        { key: 'created_at', label: this.$t('general.created_at'), sortable: true, formatter: (v) => formatLocalDateTime(v), tdAttr: { style: 'cursor:pointer' } },
        // { key: 'id', label: this.$tc('general.action', 1) },
      ]
    },
    items() {
      return this.actionLogs?.data ?? []
    },
    dateRange: {
      get() {
        return {
          startDate: formatLocalDateTime(this.queryParams.start, 1, false, true),
          endDate: formatLocalDateTime(this.queryParams.end, 1, false, true),
        }
      },
      set(newVal) {
        this.queryParams['start'] = formatDayStartUtc(newVal.startDate, 0, false, true)
        this.queryParams['end'] = formatDayEndUtc(newVal.endDate, 0, false, true)
        return newVal
      },
    },
    actionParams() {
      try {
        return this.actionTarget?.params ? JSON.stringify(JSON.parse(this.actionTarget.params), null, 4) : this.$t('no_data')
      } catch (error) {
        return ''
      }
    },
    actionResponse() {
      try {
        return this.actionTarget?.response ? JSON.stringify(JSON.parse(JSON.parse(this.actionTarget.response)), null, 4) : this.$t('no_data')
      } catch (error) {
        return ''
      }
    },
  },
  methods: {
    getList() {
      this.isLoading = true
      const params = {}
      for (const key in this.queryParams) {
        if (this.queryParams[key]) {
          params[key] = this.queryParams[key]
        }
      }
      this.$store
        .dispatch('request/getActionLogs', params)
        .then(() => (this.isLoading = false))
        .catch(() => (this.isLoading = false))
    },
    onSearch() {
      this.queryParams['page'] = 1
      this.getList()
    },
    onReset() {
      this.queryParams.action = null
      this.queryParams.name = null
      this.queryParams.start = null
      this.queryParams.end = null
      this.onSearch()
    },
    rowClicked(item, index, evt) {
      this.actionTarget = item
      this.$bvModal.show('action-log-details')
    },

    formatLocalDateTime,
  },
  created() {
    this.getList()
  },
  beforeRouteLeave(to, from, next) {
    this.$set(from.params, 'preservedParams', this.queryParams)
    return next()
  },
}
</script>
<style scoped>
::v-deep .modal-dialog {
  width: fit-content;
  min-width: 30%;
  max-width: 50%;
}
@media screen and (max-width: 1024px) {
  ::v-deep .modal-dialog {
    min-width: 75%;
    max-width: 90%;
  }
}
</style>
