<template>
  <card body-classes="p-0 d-flex flex-column" class="table-custom">
    <loading :active="isLoading" :can-cancel="false" :is-full-page="false" />
    <div class="px-4 pt-3 pb-2 d-flex w-100 align-items-center justify-content-between">
      <b-button @click="onExport" class="mb-2 mr-2"><b-icon-file-spreadsheet class="mr-1" />{{ isLoading ? $t('status.loading') : $t('action.export') }}</b-button>
      <b-icon-info-circle-fill class="mb-2 text-lg text-twitter" v-b-tooltip.hover.left="$t('report.hourly_data_notice') + `${updateTime} (${localTz}).`" />
    </div>

    <b-table :fields="fields" :items="weeklyReport" show-empty :empty-text="$t('notify.table_no_records')" small hover responsive head-variant="light" class="m-0 p-0"> </b-table>
  </card>
</template>
<script>
import { exportAsExcel } from '@/utils/fileUtils'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      isLoading: false,
      weeklyReport: [],
      weekHeader: [],
      updateTime: null,
    }
  },
  computed: {
    ...mapGetters({ reEnterWeeklyReport: 'request/reEnterWeeklyReport', localTz: 'settings/timezone' }),
    fields() {
      return [
        { key: 'from', label: this.$t('general.start_at'), sortable: true },
        { key: 'to', label: this.$t('general.end_at'), sortable: true },
        { key: 'user_amount', label: this.$t('report.competition.users_amount') },
        ...this.weekHeader,
      ]
    },
  },
  watch: {
    reEnterWeeklyReport(newVal, oldVal) {
      this.weeklyReport = newVal
      // first one is the longest
      for (const i in newVal[0]) {
        if (i.includes('week')) {
          const num = i.split('_')
          this.weekHeader.push({ key: 'week_' + num[1], label: this.$t('general.week') + ' ' + num[1] })
        }
      }
    },
  },
  methods: {
    getUpdateTime() {
      var now = this.$moment.utc()
      if (now.minutes() <= 31) {
        this.updateTime = now.subtract(1, 'hour').tz(this.localTz).format('YYYY-MM-DD HH:00:00 Z')
      } else {
        this.updateTime = now.tz(this.localTz).format('YYYY-MM-DD HH:00:00 Z')
      }
    },
    getList() {
      this.isLoading = true
      this.$store
        .dispatch('request/getReEnterWeeklyReport')
        .then(() => (this.isLoading = false))
        .catch(() => (this.isLoading = false))
    },
    onExport() {
      if (this.weeklyReport.length > 0) {
        const weeks = this.weekHeader.map((v) => v.label)
        const weekCodes = this.weekHeader.map((v) => v.key)
        const headings = ['from', 'to', 'user_amount', ...weekCodes]
        const headingsFormat = [[this.$t('general.start_at'), this.$t('general.end_at'), this.$t('report.competition.users_amount'), ...weeks]]
        return exportAsExcel(
          this.$t('sidebar.re_enter_report'),
          null, // no need to fetch data from url because no pagination
          this.weeklyReport, // pass data as params because no pagination
          this.weeklyReport.length, // total
          null,
          headings,
          headingsFormat,
          (bool) => (this.isLoading = bool)
        )
      } else {
        this.$notify({ group: 'root', type: 'error', text: this.$t('notify.no_records') })
      }
    },
  },
  mounted() {
    this.getList()
    this.getUpdateTime()
  },
}
</script>
