var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('card',{staticClass:"table-custom",attrs:{"body-classes":"p-0 d-flex flex-column"}},[_c('div',{staticClass:"px-4 py-3 w-100 d-flex flex-row justify-content-end"},[_c('b-button',{attrs:{"variant":"basic"},on:{"click":function($event){return _vm.openUpsert(null)}}},[_c('b-icon-plus',{staticClass:"mr-1"}),_vm._v(_vm._s(_vm.$t('donation.create_new_donation')))],1)],1),_c('b-table',{staticClass:"m-0 p-0",attrs:{"fields":_vm.fields,"items":_vm.items,"show-empty":"","empty-text":_vm.$t('notify.table_no_records'),"small":"","hover":"","responsive":"","head-variant":"light","primary-key":"id","sticky-header":"100%"},scopedSlots:_vm._u([{key:"cell(title)",fn:function(ref){
var value = ref.value;
return [_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",value:(value),expression:"value",modifiers:{"hover":true,"right":true}}]},[_vm._v(_vm._s(value))])]}},{key:"cell(is_active)",fn:function(ref){
var value = ref.value;
return [_c('bool-badge',{attrs:{"value":value,"text":value == 1 ? _vm.$t('status.active') : _vm.$t('status.inactive')}})]}},{key:"cell(image_url)",fn:function(ref){
var value = ref.value;
return [(value)?_c('b-img-lazy',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",value:(value),expression:"value",modifiers:{"hover":true,"right":true}}],staticStyle:{"max-height":"4rem"},attrs:{"src":value,"alt":_vm.shortenString(value, 13, true),"fluid":"","block":"","rounded":""}}):_vm._e()]}},{key:"cell(description)",fn:function(data){return [_c('b-form-textarea',{staticStyle:{"font-size":"0.85rem","min-width":"10rem"},attrs:{"disabled":"","size":"lg","rows":"2","no-resize":""},model:{value:(data.value),callback:function ($$v) {_vm.$set(data, "value", $$v)},expression:"data.value"}})]}},{key:"cell(action)",fn:function(ref){
var item = ref.item;
return [_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(_vm.$t('action.edit')),expression:"$t('action.edit')",modifiers:{"hover":true,"top":true}}],on:{"click":function($event){return _vm.openUpsert(item)}}},[_c('b-icon-pencil')],1)]}}])}),(_vm.items.length > 0)?_c('paginate',{staticClass:"card-footer",attrs:{"queries":_vm.queryParams,"fetcher":_vm.getDonationList,"total":_vm.donationList.total}}):_vm._e(),_c('DonationUpsert',{attrs:{"donation":_vm.actionTarget}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }