<template>
  <div style="display: contents">
    <loading :active="isLoading" :can-cancel="false" :is-full-page="false" />
    <template v-if="!isLoading">
      <CompetitionUpsert v-if="details" :initVal="details" :isEditRoute="false" />
      <CompetitionUpsert v-else :isEditRoute="false" />
    </template>
  </div>
</template>
<script>
import CompetitionUpsert from '@/components/Competitions/CompetitionUpsert.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    CompetitionUpsert,
  },
  data() {
    return {
      isLoading: true,
      details: null,
    }
  },
  computed: {
    ...mapGetters({ competitionDetails: 'request/competitionDetails', catalogList: 'request/catalogList' }),
  },
  methods: {
    onDuplicate() {
      // filter out some unnecessary data when duplicate competition
      if (this.$route.params.id) {
        this.isLoading = true
        this.$store
          .dispatch('request/getCompetitionDetails', { id: this.$route.params.id })
          .then(() => {
            const data = { ...this.$route.params, ...this.competitionDetails }
            this.details = { status: 0, stores: [], allow_multi_stores: 0 }
            for (const key in data) {
              if (['attributes', 'prices', 'images'].indexOf(`${key}`) > -1) {
                this.details[key] = data[key]
                  .filter((x) => x.store_id == data.store_id)
                  .map((obj) => {
                    delete obj.id
                    delete obj.competition_id
                    delete obj.created_at
                    delete obj.updated_at
                    return obj
                  })
              } else if (key == 'catalogs') {
                this.details[key] = data[key]
                  .filter((x) => x.store_id == data.store_id)
                  .map((obj) => {
                    delete obj.pivot.competition_id
                    return obj
                  })
              } else if (key == 'rewards') {
                this.details[key] = data[key]
                  .filter((x) => x.type == 'winner' && x.product.store_id == data.store_id && x.product.available_amount > 0)
                  .map((obj) => {
                    delete obj.id
                    delete obj.competition_id
                    delete obj.created_at
                    delete obj.updated_at
                    return obj
                  })
              } else if (!['id', 'status', 'created_at', 'updated_at', 'start_time', 'end_time', 'draw_date', 'url', 'stores', 'allow_multi_stores', 'is_approval'].includes(String(key))) {
                this.details[key] = data[key]
              } // exclude values that need no duplication
            }
            this.isLoading = false
          })
          .catch(() => (this.isLoading = false))
      } else {
        this.isLoading = false
      }
    },
  },
  created() {
    if (!this.catalogList) {
      this.$store.dispatch('request/getCatalogList').then(() => this.onDuplicate())
    } else {
      this.onDuplicate()
    }
  },
}
</script>
