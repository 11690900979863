<template>
  <card body-classes="p-0 d-flex flex-column" class="table-custom">
    <loading :active="isLoading" :can-cancel="false" :is-full-page="false" />
    <div class="px-4 py-3 w-100 h-auto d-flex flex-row align-items-center">
      <b-form-input v-model="filter" type="search" :placeholder="$t('action.search')" style="width: 180px" />
      <template v-if="roleOpts.length > 0">
        <b-form-select ref="roleSelect" v-model="selectedRole" :options="roleOpts" class="ml-2 text-capitalize" style="width: 122px">
          <b-form-select-option selected hidden disabled :value="null">{{ $tc('admin.role', 1) }}</b-form-select-option>
        </b-form-select>
        <span class="text-xs ml-2" :class="selectedRole ? '' : 'text-danger'">
          {{ selectedRole ? $t('admin.permission_selected', { num: selectedPer.length + '/' + items.length }) : '⚠' + $t('admin.select_role_notice') }}
        </span>
      </template>
    </div>
    <b-form @submit.prevent="onSubmit" id="role-permission-upsert-form" style="display: contents">
      <b-table
        show-empty
        :empty-text="$t('notify.table_no_records')"
        small
        striped
        hover
        responsive
        head-variant="light"
        class="m-0 p-0"
        :fields="[
          { key: 'path', label: $t('admin.api_path'), tdClass: 'font-weight-bold' },
          { key: 'name', label: $t('general.name') },
        ]"
        :items="items"
        :filter="filter">
        <template #cell(name)="{ value, item }">
          <b-form-checkbox
            v-model="selectedPer"
            :id="`permission-${item.id}`"
            :name="`permission-${item.id}`"
            :value="item.id"
            :disabled="!selectedRole"
            class="m-0 p-0 font-weight-bold text-capitalize"
            >{{ value.replace(/[.-]/g, ' ') }}
          </b-form-checkbox>
        </template>
      </b-table>
    </b-form>
    <div class="px-4 pt-2 pb-3 m-0 d-flex flex-row align-items-center justify-content-end" v-if="roleOpts.length > 0">
      <b-button variant="primary" type="submit" form="role-permission-upsert-form" :disabled="!selectedRole">{{ $t('action.save') }}</b-button>
      <b-button variant="basic" @click="onReset" :disabled="!selectedRole">{{ $t('action.cancel') }}</b-button>
    </div>
  </card>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      isLoading: false,
      selectedRole: null,
      selectedPer: [],
      filter: null,
    }
  },
  computed: {
    ...mapGetters({ roleList: 'request/roleList', permissionList: 'request/permissionList' }),
    items() {
      return this.permissionList
    },
    roleOpts() {
      const tmp = this.roleList?.map((x) => ({ value: x.id, text: x.name.replace(/-/g, ' '), class: 'text-capitalize' }))
      return tmp ?? []
    },
  },
  methods: {
    getRoleList() {
      this.isLoading = true
      this.$store
        .dispatch('request/getRoleList')
        .then(() => (this.isLoading = false))
        .catch(() => (this.isLoading = false))
    },
    onSubmit(evt) {
      this.isLoading = true
      const roleName = this.roleOpts.find((x) => x.value == this.selectedRole)?.text
      let params = {
        role_id: this.selectedRole,
        permission_ids: this.selectedPer,
        successMsg: this.$t('admin.update_permission_notice[0]', { role: roleName }),
        errorMsg: this.$t('admin.update_permission_notice[1]', { role: roleName }),
      }
      this.$store
        .dispatch('request/updateRolePermission', params)
        .then((res) => {
          this.isLoading = false
          if (res?.status == 200) {
            this.getRoleList()
          }
        })
        .catch(() => (this.isLoading = false))
    },
    onReset(evt) {
      this.selectedRole = null
      this.selectedPer = []
      // this.$refs.roleSelect.focus()
    },
  },
  watch: {
    selectedRole(newVal, oldVal) {
      const role = this.roleList.find((role) => role.id === newVal)
      this.selectedPer = role?.permissions.map((x) => x.id)
    },
  },
  mounted() {
    if (!this.roleList) {
      this.getRoleList()
    }
    if (!this.permissionList) {
      this.isLoading = true
      this.$store
        .dispatch('request/getPermissionList')
        .then(() => (this.isLoading = false))
        .catch(() => (this.isLoading = false))
    }
  },
}
</script>
